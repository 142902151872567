import React, { useContext, useEffect, useRef } from "react"
import { ArticleTemplateProps } from "./templates"
import { graphql } from "gatsby"
import { Layout } from "../components/atoms/Layout"
import { SEO } from "../components/atoms/SEO"
import BlockContent from "../components/atoms/BlockContent"
import { Image } from "../components/atoms/Image"
import tw from "twin.macro"
import { ShareButton } from "../components/atoms/ShareButton"
import { ImageBlocks, ToyotaCare } from "../components/organisms/Series"
import { DealersNearYou } from "../components/organisms/DealersNearYou"
import { ButtonLink } from "../components/atoms/Button"
import {
  getCTALinkValue,
  parseDisclaimerBlocks,
  windowExists,
} from "../helpers"
import useTealiumEvent from "../hooks/Tealium/useTealiumEvent"
import { CTA } from "../global"
import { useTealiumContext } from "../contexts/Tealium"
import useTealiumView from "../hooks/Tealium/useTealiumView"
import { ImageBlocksWithDescription } from "../components/molecules/ImageBlocksWithDescription"
import CtaBox from "../components/molecules/Heros/CtaBox"
import { LocationContext } from "../contexts/Location"
import useDealers from "../hooks/useDealers"
import useTealiumNoZip from "../hooks/Tealium/useTealiumNoZip"
import { Back } from "../components/atoms/Back"
import { LanguageContext } from "../contexts/Language"
import Coupons from "../components/molecules/Coupons/Coupons"
import { DisclaimersContext } from "../contexts/Disclaimers"
import { toggleDisclaimersModal } from "../contexts/Disclaimers/actions"

const ArticlePage: React.FC<ArticleTemplateProps> = ({
  data,
  location,
}: any) => {
  const { page } = data
  const { language, _ } = useContext(LanguageContext)
  const [state, dispatch] = useContext(DisclaimersContext)

  // Tealium
  // const { trackTealEvent } = useTealiumEvent()
  // const { handleTealView } = useTealiumView()
  // const { tealPageData } = useTealiumContext()
  // const handleClick = (cta: CTA) => {
  //   const lowerCaseTitle = cta?.title ? cta.title.toLowerCase() : ""
  //   if (
  //     lowerCaseTitle === "select your dealer" ||
  //     lowerCaseTitle === "selecciona un concesionario"
  //   ) {
  //     trackTealEvent({
  //       tealium_event: `lander_anchor`,
  //       click_text: `parts service subnav:${cta?.title}`,
  //       page_name: `car care article`,
  //       anchor_button_text: `${cta?.title}`,
  //       page_type: `parts service`,
  //     })
  //   }
  //   if (
  //     lowerCaseTitle === "view all coupons" ||
  //     lowerCaseTitle === "ver más cupones"
  //   ) {
  //     trackTealEvent({
  //       tealium_event: `nav_click`,
  //       click_text: `parts service subnav:${cta?.title}`,
  //       page_name: `car care article`,
  //       page_type: `parts service`,
  //     })
  //   }
  // }
  // useEffect(() => {
  //   handleTealView({
  //     page_name: "car care article",
  //     page_type: "parts service",
  //     tealium_event: "view",
  //   })
  // }, [])

  //Tealium

  const { handleTealView } = useTealiumView()
  const [{ dealers }] = useContext(LocationContext)
  const pageload = useRef(true)
  const { getDealerInfo } = useDealers()
  const [isOutOfArea] = useTealiumNoZip()

  useEffect(() => {
    if (windowExists() && location?.hash?.includes("#dealersNearYou")) {
      const dealersNearYouEl = document.getElementById("dealersNearYou")
      const dealersNearYouPosition = dealersNearYouEl.getBoundingClientRect()
      window.scroll({
        top:
          dealersNearYouPosition.top +
          window.innerHeight * 0.85 +
          window.scrollY,
        behavior: "smooth",
      })
    }
  }, [])

  useEffect(() => {
    if (pageload.current && isOutOfArea) {
      pageload.current = false
      handleTealView({
        page_name: "car care article",
        tealium_event: "view",
        search_results: 0,
        dealer_code: "NOT SET",
        dealer_name: "NOT SET",
        page_type: "parts service",
      })
    }

    const dealerInfo = getDealerInfo()
    // Fire only on inital page load, and not every time dealers is updated
    if (pageload.current && dealers) {
      pageload.current = false
      handleTealView({
        tealium_event: "view ",
        page_name: "car care article",
        search_results: dealerInfo.searchResults,
        dealer_code: dealerInfo.dealerCodes,
        dealer_name: dealerInfo.dealerNames,
        page_type: "parts service",
      })
    }
  }, [dealers, isOutOfArea])

  const ctaBoxData = page?.content?.find(
    (section: any) => section?._type === "callToActionBox"
  )

  return (
    <Layout>
      <SEO
        url={location.href}
        title={page.pageTitle}
        keywords={page.seo?.keywords}
        schema={page.seo?.schema}
        description={page.seo?.description}
        canonicalPath={page.seo?.canonicalPath}
      />
      {ctaBoxData && <CtaBox {...ctaBoxData} />}
      <section
        aria-label="Article Details Section"
        css={[tw`max-w-desktop px-8 mx-auto relative`, tw`lg:(px-10)`]}
      >
        <div css={[tw`w-full h-full pt-4 mt-5 flex content-center`]}>
          <div css={[tw`text-toyotaRed cursor-pointer `]}>
            <Back
              cta={_("Back to Car Care Tips")}
              link={
                language === "es" ? "/es/car-care-tips/" : "/car-care-tips/"
              }
            />
          </div>
        </div>
        <ShareButton
          title={page.title}
          iconStyles={tw`w-5`}
          css={tw`absolute top-0.5 right-4 lg:right-8`}
        />
        {page.categories.length > 0 && (
          <div
            css={[
              tw`text-xl uppercase text-red-500 mt-8 mb-4 font-semibold pr-4 lg:pr-6`,
            ]}
          >
            {page.categories[0].category}
          </div>
        )}
        <h2
          css={[
            tw`text-5xl mb-8 font-semibold w-full`,
            tw`lg:(text-6xl w-4/5)`,
          ]}
        >
          {parseDisclaimerBlocks(page.title, selection =>
            dispatch(toggleDisclaimersModal(selection))
          )}
        </h2>
        <div css={[tw`mb-12 text-xl w-full`, tw`lg:(text-2xl w-4/5)`]}>
          <BlockContent data={page.excerpt} />
        </div>
        <Image
          transparentPreview
          imageData={page.featuredImage?.image}
          css={[tw`w-full`]}
          objectFit="cover"
        />
        <div css={[tw`text-lg pb-5`]}>
          <SanitySections data={page} />
        </div>
      </section>
      <SanityContentSections data={page} />
    </Layout>
  )
}

const SanitySections = ({ data }: any): JSX.Element => {
  let imageSizeStyle = {}

  switch (data.bodyImageSize) {
    case "small":
      imageSizeStyle = { width: "25%", height: "auto" }
      break
    case "medium":
      imageSizeStyle = { width: "50%", height: "auto" }
      break
    case "large":
      imageSizeStyle = { width: "100%", height: "auto" }
      break
    default:
      imageSizeStyle = {}
  }

  return (
    <div css={[tw`my-10`]}>
      {data.body.map((section: any, index: number) => {
        switch (section._type) {
          case "block":
            return <BlockContent key={index} data={section} />
          case "image":
            return (
              <div key={index}>
                <Image imageData={section} css={imageSizeStyle} />
              </div>
            )
          case "bodyImageSize":
            // Ignore the bodyImageSize section itself in rendering
            return null
          default:
            return null
        }
      })}
    </div>
  )
}

const SanityContentSections = ({ data }: any): JSX.Element => {
  return (
    <div css={[tw``]}>
      {data?.content?.map((section: any) => {
        switch (section?._type) {
          case "imageBlocks":
            const imageBlocksData = section?.callouts
            return (
              <ImageBlocks
                imageBlocks={imageBlocksData}
                _key={"Image Blocks"}
                sectionSlug={{
                  current: "",
                }}
              />
            )
          case "dealersNearYouSection":
            return (
              <DealersNearYou
                type="default"
                {...section}
                ctaAnalyticsId="schedule service"
              />
            )
          case "imageWithText":
            return <ToyotaCare {...section} imageWithTextcta={section.cta} />
          case "couponsSection":
            return <Coupons {...section} />
          case "imageBlocksWithDescriptionSection":
            return <ImageBlocksWithDescription section={section} />
          case "generalContent":
            return (
              <div
                css={[
                  tw`max-w-desktop py-10 mx-auto text-lg pb-14 px-6`,
                  tw`sm:(px-8)`,
                  tw`lg:(px-10)`,
                ]}
              >
                <BlockContent data={section.contentBody} />
              </div>
            )
        }
      })}
    </div>
  )
}

export const query = graphql`
  query ArticlePageQuery($id: String) {
    page: sanityPageArticle(id: { eq: $id }) {
      id
      language
      _type
      title
      pageTitle
      excerpt: _rawExcerpt(resolveReferences: { maxDepth: 5 })
      body: _rawBody(resolveReferences: { maxDepth: 5 })
      bodyImageSize
      featuredImage {
        ...SanityImageData
      }
      categories {
        category
      }
      seo {
        keywords
        schema
        description
        canonicalPath
      }
      disclaimers {
        code
        disclaimer
      }
      content {
        ... on SanityGeneralContent {
          _type
          title
          contentBody: _rawBody(resolveReferences: { maxDepth: 5 })
        }
        ...ImageBlocksData
        ...DealersNearYouData
        ...CallToActionBoxData
        ...ImageWithTextData
        ...CouponsSectionData
        ...ImageBlocksWithDescriptionData
      }
    }
  }
`

export default ArticlePage
