import React, { useContext, useEffect, useState } from "react"
import tw from "twin.macro"
import { useTealiumContext } from "../../../contexts/Tealium"
import { CTA } from "../../../global"
import { getCTALinkValue, parseDisclaimerBlocks } from "../../../helpers"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { ButtonLink } from "../../atoms/Button"
import { CtaBoxProps } from "./Heros.d"
import { toggleDisclaimersModal } from "../../../contexts/Disclaimers/actions"
import { DisclaimersContext } from "../../../contexts/Disclaimers"

const CtaBox: React.FC<CtaBoxProps> = ({ heading, description, ctas }) => {
  const { trackTealEvent } = useTealiumEvent()
  const { tealPageData } = useTealiumContext()
  const [showFloatingDiv, setShowFloatingDiv] = useState(true)
  const [showFooterDiv, setShowFooterDiv] = useState(false)
  const [fadeDiv, setFadeDiv] = useState(true)
  const [fadeFooterDiv, setFadeFooterDiv] = useState(true)

  // TOP CTA BOX
  useEffect(() => {
    const handleScroll = () => {
      const y = window.pageYOffset
      setShowFloatingDiv(y <= 650)
      setFadeDiv(y <= 600)
    }

    window.addEventListener("scroll", handleScroll)

    // Cleanup function to remove event listener when component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  // BOTTOM CTA BOX
  useEffect(() => {
    const handleScroll = () => {
      const y = window.pageYOffset
      setShowFooterDiv(y > 600)
      setFadeFooterDiv(y > 650)
    }

    window.addEventListener("scroll", handleScroll)

    // Cleanup function to remove event listener when component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const handleClick = (cta: CTA) => {
    const lowerCaseTitle = cta?.title ? cta.title.toLowerCase() : ""

    if (
      (tealPageData?.page_type === "parts service" &&
        lowerCaseTitle === "select your dealer") ||
      lowerCaseTitle === "selecciona un concesionario"
    ) {
      trackTealEvent({
        tealium_event: "lander_anchor",
        click_text: `parts service subnav:${cta?.title}`,
        anchor_button_text: `${cta?.title}`,
      })
    }
    if (
      (tealPageData?.page_type === "parts service" &&
        lowerCaseTitle === "view all coupons") ||
      lowerCaseTitle === "view coupons" ||
      lowerCaseTitle === "ver más cupones"
    ) {
      trackTealEvent({
        tealium_event: "nav_click",
        click_text: `parts service subnav:${cta?.title}`,
      })
    }
  }
  const [state, modalDispatch] = useContext(DisclaimersContext)
  return (
    <>
      {showFloatingDiv && (
        <div
          css={[
            tw`top-20 border-t border-b bg-white transition-all duration-500`,
            tw`lg:(fixed top-40 z-50 border-0 bg-transparent right-20)`,
            fadeDiv
              ? tw`transition-all duration-500 opacity-100`
              : tw`transition-all duration-500 opacity-0`,
          ]}
        >
          <article css={[tw`flex justify-center lg:(w-full pl-12)`]}>
            <div
              css={[
                tw`bg-white px-6 py-4 my-6 flex flex-col`,
                tw`lg:(max-w-lg px-8 rounded-lg shadow-4)`,
              ]}
            >
              <h3 css={[tw`font-semibold uppercase`]}>
                {parseDisclaimerBlocks(heading, selection =>
                  modalDispatch(toggleDisclaimersModal(selection))
                )}
              </h3>
              <span css={[tw`text-sm uppercase`, tw`normal-case`]}>
                {parseDisclaimerBlocks(description, selection =>
                  modalDispatch(toggleDisclaimersModal(selection))
                )}
              </span>
              {ctas && (
                <div
                  css={[
                    tw`col-span-2 col-start-1 flex mt-4 justify-center row-start-6 items-center gap-2`,
                    tw`md:(col-span-1 row-start-3 row-end-4 justify-start my-0 mt-4)`,
                  ]}
                >
                  {ctas?.map((cta: CTA) => {
                    return (
                      <ButtonLink
                        {...{ [cta?.buttonType]: true }}
                        to={getCTALinkValue(cta)}
                        target={
                          cta?.linkType === "external" ? "_blank" : "_self"
                        }
                        css={[
                          tw`w-full col-span-1 h-full flex justify-center items-center !text-xs !px-2.5 whitespace-nowrap`,
                          tw`md:(w-auto !text-sm !px-4)`,
                        ]}
                        key={cta?.title}
                        onClick={() => handleClick(cta)}
                        analytics-id={
                          cta?.title &&
                          (cta.title.toLowerCase() === "schedule service"
                            ? "zip submit:hero:"
                            : cta.title.toLowerCase() === "view all coupons" ||
                              cta.title.toLowerCase() === "ver más cupones"
                            ? "coupons:opps box:"
                            : cta.title.toLowerCase() ===
                                "select your dealer" ||
                              cta.title.toLowerCase() ===
                                "selecciona un concesionario"
                            ? "dealer:opps box:"
                            : null)
                        }
                      >
                        {cta?.title}
                      </ButtonLink>
                    )
                  })}
                </div>
              )}
            </div>
          </article>
        </div>
      )}
      {showFooterDiv && (
        <div
          css={[
            tw`fixed bottom-0 z-50 border-0 bg-transparent right-0 left-0 transition-all duration-500`,
            tw`lg:(fixed bottom-0 z-50 border-0 bg-transparent right-0)`,
            fadeFooterDiv
              ? tw`transition-all duration-500 opacity-100`
              : tw`transition-all duration-500 opacity-0`,
          ]}
        >
          <article css={[tw`justify-start w-full`]}>
            <div
              css={[
                tw`bg-black px-2 py-4 mt-6 flex`,
                tw`lg:(w-full shadow-4 px-8)`,
              ]}
            >
              <div css={[tw`hidden mr-10`, tw`lg:(inline-grid)`]}>
                <h3 css={[tw`font-semibold block uppercase text-gray-50`]}>
                  {parseDisclaimerBlocks(heading, selection =>
                    modalDispatch(toggleDisclaimersModal(selection))
                  )}
                </h3>
                <span
                  css={[tw`text-sm uppercase text-gray-50`, tw`normal-case`]}
                >
                  {parseDisclaimerBlocks(description, selection =>
                    modalDispatch(toggleDisclaimersModal(selection))
                  )}
                </span>
              </div>
              {ctas && (
                <div
                  css={[
                    tw`flex justify-center items-center gap-2`,
                    // tw`md:(col-span-2 row-start-1 row-end-4 justify-start my-0 mt-4)`,
                  ]}
                >
                  {ctas?.map((cta: CTA) => {
                    return (
                      <ButtonLink
                        {...{ [cta?.buttonType]: true }}
                        to={getCTALinkValue(cta)}
                        target={
                          cta?.linkType === "external" ? "_blank" : "_self"
                        }
                        css={[
                          tw`w-full col-span-1 h-full flex justify-center items-center !text-xs !px-2.5 whitespace-nowrap`,
                          tw`md:(w-auto !text-sm !px-4)`,
                        ]}
                        key={cta?.title}
                        onClick={() => handleClick(cta)}
                        analytics-id={
                          cta?.title &&
                          (cta.title.toLowerCase() === "schedule service"
                            ? "zip submit:hero:"
                            : cta.title.toLowerCase() === "view all coupons" ||
                              cta.title.toLowerCase() === "ver más cupones"
                            ? "coupons:opps box:"
                            : cta.title.toLowerCase() ===
                                "select your dealer" ||
                              cta.title.toLowerCase() ===
                                "selecciona un concesionario"
                            ? "dealer:opps box:"
                            : null)
                        }
                      >
                        {cta?.title}
                      </ButtonLink>
                    )
                  })}
                </div>
              )}
            </div>
          </article>
        </div>
      )}
    </>
  )
}

export default CtaBox
